import dkMarker from '../dkGisMarker'

export default  {
    mixins : [dkMarker],
    template: "<div></div>",    
    created() {

    },
    mounted() {        
        this.$marker = new google.maps.Marker({
            position : {
                lat: this.geometry.lat, 
                lng: this.geometry.lng
            },            
            draggable : this.draggable,
            map: this.mapObject
        })

        this.setEventBind()
    },
    destroyed() {
        this.$marker.setMap(null)
    },
    watch: {
        geometry(newVal, oldVal) {            
            this.$marker.setPosition({
                lat: newVal.lat, 
                lng: newVal.lng
            })
        }
    },
    methods : {
        setEventBind() {
            var me = this
            me.$events.forEach((event) => {
                if (me.$listeners[event]) {
                    google.maps.event.addListener(me.$marker, event, (ev) => {
                        me.$emit(event, {
                            event: ev,
                            properties: me.properties
                        })
                    })    
                }
            })
            
        }
    }
}