///////////////////////////////////////////////////////////////////
/*
/* Google GIS 메인 모듈
/* 
/* 구글 줌 레벨은 DK-GIS 의 역수이므로 반비례 수식을 써서 줌레벨을 구한다.
/* 줌 레벨 상수 19 + 1
/////////////////////////////////////////////////////////////////*/
import marker from './marker'
import dkMap from '../dkGisMap'

export default {        
    mixins : [dkMap],    
    components: {
        'dk-marker': marker
    },
    created() {
        //console.log("on create")
    },
    mounted() {
        console.log("google map mounted")        
        const element = this.$refs['vueDkgisContainer']            
        this.mapObject = new google.maps.Map(element, {
            zoom: this.getLocalLevel(this.gisApiInfo.level) || 17,
            center: {
                lat: this.gisApiInfo.geometry.lat, 
                lng: this.gisApiInfo.geometry.lng
            }
          });
        this.$parent.$emit('apiLoadComplete', '구글 로딩 컴플릿')
        return {

        }
    },
    destroyed() {
        console.log("google map destroyed")
    },
    methods: {
        getLevel() {
            var dkLevel = (this.mapObject.getZoom() * -1) + 20
            return dkLevel
        },
        
        getLocalLevel(level) {
            return 20 - level
        },

        setLevel(level) {
            var dkLevel = 20 - level
            this.mapObject.setLevel(dkLevel)
        },

        getCenter() {
         
        },        
        setCenter(coord) {


        },

        showTrafficLayer(flag) {
            return false    
        },

        clickMarker(ev) {            
            this.$parent.$emit("clickMarker", ev)
        },
        setTrafficLayer(flag) {
            return false
        },

        refresh() {
            
        }
    }
}