var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "vueDkgisContainer", staticClass: "vue-dkgis-container" },
    _vm._l(_vm.markerData, function(marker) {
      return _c("dk-marker", {
        key: marker.seq,
        attrs: {
          data: marker,
          mapObject: _vm.mapObject,
          geometry: marker.geometry,
          properties: marker.properties,
          draggable: marker.draggable
        },
        on: { click: _vm.clickMarker }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }